import React, {
  useState,
  useEffect,
} from 'react'
import {
  useParams
} from 'react-router-dom'
import {
  TimezoneDropdown,
  IconTextCombo,
} from 'components'
import {
  Alert
} from 'icons'
import {
  TIMEZONE_MAP,
  formatDate,
  navigateTo,
  customAlert,
  confirmDialog,
  formatTimeslotAccepted
} from 'utils-em'
import {
  Box,
  Button,
  Divider,
  Grid,
  ToggleButton,
  Typography
} from '@mui/material'
import moment from 'moment'
import {
  useEngagement,
  useJsonAPIUpsert
} from 'hooks'
import { useSelector } from 'react-redux'
import CallAvailability from '../CallAvailability'

const TimeSelectionStep = () => {
  const userId = useSelector(({ session }) => session.id)
  const { upsert } = useJsonAPIUpsert()
  const engagementId = parseInt(useParams().engagementId, 10)
  const {
    proposedTimeslotProposal,
    advisor,
    customerUser,
    isFirstProposal,
    isParticipant,
    isAdvisor
  } = useEngagement(engagementId)
  const { timeslots } = proposedTimeslotProposal
  const user = isAdvisor ? advisor : customerUser
  const otherUser = isAdvisor ? customerUser : advisor

  const [timezone, setTimezone] = useState(user.timezone || moment.tz.guess())
  const hasTimezoneDifference = timezone !== otherUser?.timezone
  useEffect(() => {
    if (timeslots && timezone) setTimeslotSelectionsByDay(groupTimeSlotsByDayLocal(timeslots, timezone))
  }, [timezone, timeslots?.count])
  const [timeslotSelectionsByDay, setTimeslotSelectionsByDay] = useState({})
  const groupTimeSlotsByDayLocal = (slots, currentTimezone) => {
    const groupedTimeslots = {}
    const localSlots = slots ?? []
    localSlots.forEach((slot) => {
      const day = moment(slot.startTime).tz(currentTimezone).startOf('day').format('YYYY-MM-DD')
      if (!groupedTimeslots[day]) {
        groupedTimeslots[day] = []
      }
      groupedTimeslots[day].push(slot)
    })
    return groupedTimeslots
  }

  const isCurrentUserSelectingTime = proposedTimeslotProposal.initiatingUserId !== userId
  const acceptTimeSlot = async (timeslot) => {
    confirmDialog({
      title: 'Accept call time',
      description: `Do you want to confirm your call for ${moment(timeslot.startTime).tz(timezone).format('MMMM D,')} ${moment(timeslot.startTime).tz(timezone).format('h:mm A')} - ${moment(timeslot.endTime).tz(timezone).format('h:mm A z')}?`,
      actions: [
        {
          name: 'Confirm',
          action: () => {
            upsert({
              type: 'timeslots',
              id: timeslot.id,
              isSelected: true,
              acceptProposal: true,
              riders: ['acceptProposal'],
              queryStringParams: { 'lazy[engagements]': 'step' }
            }).then(() => {
              customAlert(`Call scheduled for ${formatTimeslotAccepted(timeslot)}`, false)
              navigateTo(`/${isAdvisor ? 'a' : 'c'}/engagements/${engagementId}`)
            })
          }
        }
      ]
    })
  }
  const allTimeslotsInPast = timeslots?.every((slot) => moment(slot.startTime).isBefore(moment()))
  if (isCurrentUserSelectingTime && allTimeslotsInPast && !isParticipant) {
    navigateTo(`/${isAdvisor ? 'a' : 'c'}/engagements/${engagementId}#reschedule`)
  }

  if (isParticipant) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Typography variant="h3">
          {`${customerUser.firstName} will schedule your call with ${advisor.firstName}.`}
        </Typography>
        <Typography variant="body1">
          {`${customerUser.firstName} started an engagement with ${advisor.firstName}. You will be notified and Zoom details will be provided once the call is scheduled.`}
        </Typography>
      </Box>
    )
  }

  if (!isCurrentUserSelectingTime) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Typography variant="body">Once they accept a call time (or send alternative times), you'll receive an email notification.</Typography>
        <Divider />
        <CallAvailability engagementId={engagementId} />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {isFirstProposal ? (
        <>
          <Typography variant="h3">
            {`Thank you for accepting ${customerUser.firstName}'s proposal!`}
          </Typography>
          <Typography variant="body">
            {`${customerUser.firstName} has provided their availability for a one hour call. Please select a slot from the following options.`}
          </Typography>
        </>
      ) : (
        <Typography variant="body">
          {`${otherUser.firstName} did not have availability within the times you provided, and has sent over alternate times for a one hour call. Please select a slot from the following options.`}
        </Typography>
      )}
      <Box>
        <TimezoneDropdown
          initialTimezone={timezone}
          onTimezoneSelected={(e) => setTimezone(e)}
        />
        {hasTimezoneDifference && otherUser?.timezone && (
          <IconTextCombo
            icon={<Alert />}
            iconColor="neutral.darkGrey"
            text={`${otherUser.firstName} is in the ${TIMEZONE_MAP[otherUser.timezone]} (${moment().tz(otherUser?.timezone).format('Z')}) time zone`}
            typographyColor="neutral.black"
          />
        )}
      </Box>
      {Object.keys(timeslotSelectionsByDay).map((day, dayIndex) => (
        <Box
          key={day}
          id={`times-day-${dayIndex}`}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Box>
            <Typography variant="body1">{formatDate(new Date(`${day}T00:00:00`), { includeDayOfWeek: true })}</Typography>
          </Box>
          <Grid container spacing={2} direction="row">
            {timeslotSelectionsByDay[day].map((slot, timeslotIndex) => (
              <Grid item key={slot.startTime}>
                <ToggleButton
                  value={slot.startTime}
                  id={`timeslot-${dayIndex}-${timeslotIndex}`}
                  sx={{ color: 'brand.darkNavy', width: 170 }}
                  onClick={() => acceptTimeSlot(slot)}
                >
                  <Typography variant="tinyBold">
                    {`${moment(slot.startTime).tz(timezone).format('h:mm')} - ${moment(slot.endTime).tz(timezone).format('h:mm A')}`}
                  </Typography>
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
      <Box sx={{ ml: -2 }}>
        <Button onClick={() => navigateTo(`/${isAdvisor ? 'a' : 'c'}/engagements/${engagementId}#reschedule`)}>None of these times work for me</Button>
      </Box>
    </Box>
  )
}

export default TimeSelectionStep
