import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material'

import {
  useEmissaryTheme,
  useSessionUser,
  useJsonAPIGetAll
} from 'hooks'
import {
  Phone
} from 'icons'
import {
  ACTION_CONSTANTS,
  confirmDialog,
  getOrgAvailabilityStatus,
  navigateTo,
  recordUserAction,
} from 'utils-em'
import useAccountPageContext from '../hooks/useAccountPageContext'

const BookACallButton = ({ organizationId, isResponsive, hideMessage }) => {
  const { isSmOrSmaller } = useEmissaryTheme()
  const { organization } = useAccountPageContext()
  const { id, isAdmin } = useSessionUser()
  const [hasUnlimitedContract, setHasUnlimitedContract] = React.useState(false)
  const [unlimitedStatus, setUnlimitedStatus] = React.useState(null)

  const { objects: pendingEngagements, loaded: engagementLoaded, } = useJsonAPIGetAll('engagements', {
    queryStringParams: {
      filter: [
        { name: 'customerUserId', op: 'eq', val: id },
        { name: 'organizationId', op: 'eq', val: organizationId },
        {
          name: 'state',
          op: 'not_in',
          val: [
            'rejected',
            'expired',
            'completed',
            'canceled',
            'active',
            'proposal-canceled'
          ]
        },
      ]
    },
  })

  const { totalEngageableAdvisorCount } = organization || {}
  const hasPendingEngagements = hasUnlimitedContract && pendingEngagements?.length > 0

  const isDisabled = (() => {
    if (!engagementLoaded) return true
    if (!unlimitedStatus) return true
    if (hasPendingEngagements) return true
    if (isAdmin) return true
    return false
  })()

  const message = (() => {
    if (!engagementLoaded) {
      return null
    }
    if (hasPendingEngagements) {
      return 'You already have a pending call proposal for this organization.'
    }
    if (hasUnlimitedContract && totalEngageableAdvisorCount > 0) {
      return `${totalEngageableAdvisorCount} advisor${totalEngageableAdvisorCount === 1 ? '' : 's'} available`
    }
    return null
  })()

  React.useEffect(() => {
    getOrgAvailabilityStatus(id, organizationId).then((result) => {
      setUnlimitedStatus(result.status)
      setHasUnlimitedContract(result.hasUnlimitedContract)
    })
  }, [id, organizationId])

  const handleClick = (e) => {
    if (unlimitedStatus === 'depleted') {
      confirmDialog({
        title: 'You\'re out of account credits',
        description: 'We\'re sorry, your organization has used all of the allotted account credits on its contract and a call with this account is unavailable at this time.',
        size: 'sm'
      })
      recordUserAction(ACTION_CONSTANTS.outOfAccountCredits, { id: organizationId }, 'organization')
      return
    }

    e.currentTarget.blur()
    navigateTo(hasUnlimitedContract ? `/c/talk-to-someone/${organizationId}` : '#coaching')
  }

  if (isResponsive && isSmOrSmaller) {
    return (
      <IconButton
        disabled={isDisabled}
        onClick={handleClick}
        sx={{ color: 'primary.main', '&:hover': { backgroundColor: 'primary.lightest' } }}
      >
        <Phone />
      </IconButton>
    )
  }

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Button
        disabled={isDisabled}
        variant="contained"
        onClick={handleClick}
        id="book-a-call"
      >
        Book a call
      </Button>
      {!hideMessage && message && (
        <Typography variant="tiny">
          {message}
        </Typography>
      )}
    </Box>
  )
}

BookACallButton.defaultProps = {
  isResponsive: false,
  hideMessage: false
}

BookACallButton.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isResponsive: PropTypes.bool,
  hideMessage: PropTypes.bool
}

export default BookACallButton
